<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <m-card :title="$t('shared.add')">
      <base-form v-model="model" :permissions="permissions" />

      <template #actions>
        <page-footer @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    async submit() {
      try {
        this.progressing = true
        this.model = await this.$api.roles.create(this.model)
        this.$showSuccess(this.$t('roles.roleAdded'))
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
